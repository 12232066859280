import { Scale } from "../../models/scale";
import { Chord } from "../../models/chord";
import { ChordPosition } from "../../models/chord-position";
import { drop } from "../../../iterator/drop";

export class ChordGenerator {
  constructor(private scale: Scale) {}

  generate(position: ChordPosition): Chord {
    const offset = position - 1;
    const [tonic, , second, , third] = drop(this.scale.notes(), offset);
    const chord = new Chord(tonic, second, third);
    console.log(
      `chord = ${chord
        .pitches()
        .map(
          pitch => `${pitch.pitchClass} ${pitch.octave} (${pitch.frequency})`
        )}`
    );
    return chord;
  }
}
